"use client";

import { RefineThemes } from "@refinedev/antd";
import { App as AntdApp, ConfigProvider, theme } from "antd";
import Cookies from "js-cookie";
import React, { PropsWithChildren, createContext, useEffect, useState, useMemo } from "react";
type ColorModeContextType = {
  mode: string;
  setMode: (mode: string) => void;
};
export const ColorModeContext = createContext<ColorModeContextType>({} as ColorModeContextType);
type ColorModeContextProviderProps = {
  defaultMode?: string;
};
export const ColorModeContextProvider: React.FC<PropsWithChildren<ColorModeContextProviderProps>> = ({
  children,
  defaultMode
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [mode, setMode] = useState(defaultMode || "light");
  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {
    if (isMounted) {
      const theme = Cookies.get("theme") || "light";
      setMode(theme);
    }
  }, [isMounted]);
  const setColorMode = () => {
    if (mode === "light") {
      setMode("dark");
      Cookies.set("theme", "dark");
    } else {
      setMode("light");
      Cookies.set("theme", "light");
    }
  };
  const {
    darkAlgorithm,
    defaultAlgorithm
  } = theme;
  const contextValue = useMemo(() => ({
    setMode: setColorMode,
    mode
  }), [setColorMode, mode]);
  return <ColorModeContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="ColorModeContextProvider" data-sentry-source-file="index.tsx">
      <ConfigProvider
    // you can change the theme colors here. example: ...RefineThemes.Magenta,
    theme={{
      ...RefineThemes.Blue,
      algorithm: mode === "light" ? defaultAlgorithm : darkAlgorithm
    }} data-sentry-element="ConfigProvider" data-sentry-source-file="index.tsx">
        <AntdApp data-sentry-element="AntdApp" data-sentry-source-file="index.tsx">{children}</AntdApp>
      </ConfigProvider>
    </ColorModeContext.Provider>;
};