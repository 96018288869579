import { BaseRecord } from "@refinedev/core";
import { supabaseClient } from "@utility/supabase-client";
import { Avatar, Card, Divider, Flex, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
export const CategoryInfoList = ({
  record,
  isLoading,
  id
}: Readonly<{
  record: BaseRecord | undefined;
  isLoading: boolean;
  id: string;
}>) => {
  const {
    token
  } = theme.useToken();
  const [category, setCategory] = useState("");
  async function fetchCategory() {
    try {
      const categoryData = await supabaseClient.from("category").select("*").eq("id", id).single();
      setCategory(categoryData.data.name.name_en);
      return categoryData;
    } catch (error: any) {
      throw error;
    }
  }
  useEffect(() => {
    if (id != null) {
      fetchCategory();
    }
  }, []);
  return <Card loading={isLoading} bordered={false} styles={{
    body: {
      padding: "0 16px 0 16px"
    }
  }} data-sentry-element="Card" data-sentry-component="CategoryInfoList" data-sentry-source-file="CategoryInfoList.tsx">
      <Flex vertical align="center" justify="center" data-sentry-element="Flex" data-sentry-source-file="CategoryInfoList.tsx">
        <Avatar shape="square" style={{
        aspectRatio: 1,
        objectFit: "contain",
        width: "240px",
        height: "240px",
        margin: "16px auto",
        borderRadius: "8px"
      }} src={record?.image_url} alt={record?.name.name_en} data-sentry-element="Avatar" data-sentry-source-file="CategoryInfoList.tsx" />
      </Flex>
      <Flex vertical style={{
      backgroundColor: token.colorBgContainer
    }} data-sentry-element="Flex" data-sentry-source-file="CategoryInfoList.tsx">
        <Divider style={{
        margin: 0,
        padding: 0
      }} data-sentry-element="Divider" data-sentry-source-file="CategoryInfoList.tsx" />
        <div className={"flex flex-row justify-between"}>
          <h1 className={"text-[1rem] font-bold"}> Arabic Name</h1>
          <Typography.Text data-sentry-element="unknown" data-sentry-source-file="CategoryInfoList.tsx">{record?.name.name_ar}</Typography.Text>
        </div>
        <div className={"flex flex-row justify-between"}>
          <h1 className={"text-[1rem] font-bold"}> English name</h1>
          <Typography.Text data-sentry-element="unknown" data-sentry-source-file="CategoryInfoList.tsx">{record?.name.name_en}</Typography.Text>
        </div>
        <div className={"flex flex-row justify-between"}>
          <h1 className={"text-[1rem] font-bold "}> French name</h1>
          <Typography.Text data-sentry-element="unknown" data-sentry-source-file="CategoryInfoList.tsx">{record?.name.name_fr}</Typography.Text>
        </div>
      </Flex>
      {category && <div className={"flex flex-row justify-between"}>
          <h1 className={"text-[1rem] font-bold"}> Category</h1>
          <Typography.Text>{category}</Typography.Text>
        </div>}
    </Card>;
};