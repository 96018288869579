"use client";

import { ErrorComponent } from "@refinedev/antd";
import { Authenticated } from "@refinedev/core";
import { Suspense } from "react";
export default function NotFound() {
  return <Suspense data-sentry-element="Suspense" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
      <Authenticated key="not-found" data-sentry-element="Authenticated" data-sentry-source-file="not-found.tsx">
        <ErrorComponent data-sentry-element="ErrorComponent" data-sentry-source-file="not-found.tsx" />
      </Authenticated>
    </Suspense>;
}