import { Space } from "antd";
import Image from "next/image";
type TitleProps = {
  collapsed: boolean;
};
export const Title: React.FC<TitleProps> = ({
  collapsed
}) => {
  return <div data-sentry-component="Title" data-sentry-source-file="Title.tsx">
      <a href="/category">
        {collapsed ? <Image className="mb-[0rem] " src="/assets/logos/itkann-old-logo/itkann.jpg" width={50} height={50} alt={""} quality={95} /> : <Space size={12}>
            <Image className="mb-[0rem] " src="/assets/logos/itkann-logo/itkann.png" width={100} height={50} alt={""} quality={95} />

          </Space>}
      </a>
    </div>;
};