import { supabaseClient } from '@utility/supabase-client';

interface UserMetadata {
  fcm_token: string;
}

export const updateUserMetadata = async (userId: string, fcmToken: string) => {
  const { data, error } = await supabaseClient.auth.admin.updateUserById(userId, {
    user_metadata: {
      fcm_token: fcmToken,
    },
  });

  if (error) {
    console.error('Error updating user metadata:', error);
    return null;
  }

  return data;
};
