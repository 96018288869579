"use client";

import { ColorModeContext } from "@contexts/color-mode";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import { useGetIdentity, useLogout } from "@refinedev/core";
import { Layout as AntdLayout, Avatar, Space, Switch, Typography, theme, Modal, Button } from "antd";
import React, { useContext } from "react";
import { GrLogout } from "react-icons/gr";
const {
  Text
} = Typography;
const {
  useToken
} = theme;
type IUser = {
  id: number;
  name: string;
  avatar: string;
};
export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky
}) => {
  const {
    token
  } = useToken();
  const {
    data: user
  } = useGetIdentity<IUser>();
  const {
    mode,
    setMode
  } = useContext(ColorModeContext);
  const {
    mutate: logout
  } = useLogout();
  const handleLogout = () => {
    Modal.confirm({
      title: 'Do you want to logout?',
      onOk: () => logout(),
      onCancel() {}
    });
  };
  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px"
  };
  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }
  return <AntdLayout.Header style={headerStyles} data-sentry-element="unknown" data-sentry-component="Header" data-sentry-source-file="index.tsx">
        <Space data-sentry-element="Space" data-sentry-source-file="index.tsx">
          <Switch checkedChildren="🌛" unCheckedChildren="🔆" onChange={() => setMode(mode === "light" ? "dark" : "light")} defaultChecked={mode === "dark"} data-sentry-element="Switch" data-sentry-source-file="index.tsx" />
          {(user?.name || user?.avatar) && <Space style={{
        marginLeft: "8px"
      }} size="middle">
                {user?.name && <Text strong>{user.name}</Text>}
                {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
                <Button danger={true} onClick={handleLogout} style={{
          display: 'flex',
          alignItems: 'center'
        }}>
                  <GrLogout style={{
            marginRight: '5px'
          }} />
                  Logout
                </Button>
              </Space>}
        </Space>
      </AntdLayout.Header>;
};