import { EditButton, ImageField, useTable } from "@refinedev/antd";
import { BaseRecord, HttpError } from "@refinedev/core";
import { Space, Table } from "antd";
import React from "react";
export default function SubCategories({
  record
}: Readonly<{
  record: BaseRecord | undefined;
}>) {
  const {
    tableProps
  } = useTable<HttpError>({
    resource: "sub_category",
    syncWithLocation: false,
    initialSorter: [{
      field: "created_at",
      order: "desc"
    }],
    permanentFilter: [{
      field: "parent_category_id",
      operator: "eq",
      value: record?.id
    }],
    initialPageSize: 4,
    queryOptions: {
      enabled: record !== undefined
    }
  });
  return <div data-sentry-component="SubCategories" data-sentry-source-file="SubCategories.tsx">
      <h2 className="text-xl font-bold mb-4">Subcategories</h2>
      <Table {...tableProps} rowKey="id" loading={tableProps?.loading} data-sentry-element="Table" data-sentry-source-file="SubCategories.tsx">
        <Table.Column dataIndex={["image_url"]} title="Image" render={(value: any) => <ImageField style={{
        maxWidth: "50px"
      }} value={value} />} data-sentry-element="unknown" data-sentry-source-file="SubCategories.tsx" />
        <Table.Column dataIndex={["name", "name_en"]} title={"name EN"} data-sentry-element="unknown" data-sentry-source-file="SubCategories.tsx" />
        <Table.Column dataIndex={["name", "name_fr"]} title={"name FR"} data-sentry-element="unknown" data-sentry-source-file="SubCategories.tsx" />
        <Table.Column dataIndex={["name", "name_ar"]} title={"name AR"} data-sentry-element="unknown" data-sentry-source-file="SubCategories.tsx" />
        <Table.Column title={"Actions"} dataIndex="actions" render={(_, record: BaseRecord) => <Space>
              <EditButton hideText size="small" type={"primary"} onClick={() => window.location.href = `/sub-category/edit/${record.id}`} />
            </Space>} data-sentry-element="unknown" data-sentry-source-file="SubCategories.tsx" />
      </Table>
    </div>;
}