"use client";

import { Header } from "@components/header";
import { CustomSider } from "@components/Sider/Sider";
import { Title } from "@components/Title/Title";
import { ThemedLayoutV2 } from "@refinedev/antd";
import React from "react";
export const ThemedLayout = ({
  children
}: React.PropsWithChildren) => {
  return <ThemedLayoutV2 Sider={CustomSider} Title={Title} Header={() => <Header sticky />} data-sentry-element="ThemedLayoutV2" data-sentry-component="ThemedLayout" data-sentry-source-file="index.tsx">{children}</ThemedLayoutV2>;
};