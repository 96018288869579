import { Title } from "@components/Title/Title";
import { ThemedSiderV2 } from "@refinedev/antd";
export const CustomSider = () => {
  return <ThemedSiderV2 Title={Title} fixed={true} render={({
    items
  }) => {
    return <>
                        {items}
                           </>;
  }} data-sentry-element="ThemedSiderV2" data-sentry-component="CustomSider" data-sentry-source-file="Sider.tsx" />;
};