import { useEffect, useState } from "react";
import { getMessaging, getToken, Messaging } from "firebase/messaging";
import app from "../firebase/firebase";
import { supabaseClient } from "@utility/supabase-client";
import { updateUserMetadata } from "@utility/user/update-user-meta-data/update-user-meta-data";
const useFcmToken = () => {
  const [token, setToken] = useState<string>("");
  const [notificationPermissionStatus, setNotificationPermissionStatus] = useState<NotificationPermission>("default");
  useEffect(() => {
    console.log("useFcmToken: useEffect triggered");
    const registerServiceWorker = async () => {
      if ("serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
          console.log("Service Worker registration successful with scope: ", registration.scope);
        } catch (error) {
          console.log("Service Worker registration failed: ", error);
        }
      }
    };
    const retrieveToken = async () => {
      try {
        if (isServiceWorkerAvailable()) {
          console.log("useFcmToken: serviceWorker is available");
          const messaging = getMessaging(app);
          let permission = await requestNotificationPermission();
          console.log("useFcmToken: Notification permission:", permission);
          setNotificationPermissionStatus(permission);
          while (permission !== "granted") {
            permission = await requestNotificationPermission();
            console.log("useFcmToken: Retrying notification permission:", permission);
            setNotificationPermissionStatus(permission);
          }
          if (permission === "granted") {
            await handlePermissionGranted(messaging);
          }
        }
      } catch (error) {
        console.log("An error occurred while retrieving token:", error);
      }
    };
    const isServiceWorkerAvailable = (): boolean => {
      return typeof window !== "undefined" && "serviceWorker" in navigator;
    };
    const requestNotificationPermission = async (): Promise<NotificationPermission> => {
      return await Notification.requestPermission();
    };
    const handlePermissionGranted = async (messaging: Messaging): Promise<void> => {
      const currentToken = await getToken(messaging, {
        vapidKey: "BM5FD5Pg3K4-sONbgoNWwr9l2GfhPuVSBmVcSqSnjwX32h4OJjOu6VH977knntljn4rZi8f-mdwmAGyku5hMFn8"
      });
      if (currentToken) {
        console.log("useFcmToken: Current token retrieved:", currentToken);
        setToken(currentToken);
        await updateUserMetadataWithToken(currentToken);
      } else {
        console.log("No registration token available. Request permission to generate one.");
      }
    };
    const updateUserMetadataWithToken = async (currentToken: string): Promise<void> => {
      const user = (await supabaseClient.auth.getUser()).data.user;
      if (user) {
        await updateUserMetadata(user.id, currentToken);
        console.log("useFcmToken: User metadata updated with FCM token");
      }
    };
    const initialize = async () => {
      await registerServiceWorker();
      await retrieveToken();
    };
    initialize();
  }, []);
  return {
    fcmToken: token,
    notificationPermissionStatus
  };
};
export default useFcmToken;