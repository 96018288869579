import(/* webpackMode: "eager" */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@refinedev/antd/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@refinedev/core/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@refinedev/kbar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@refinedev/nextjs-router/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/src/app/global.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@refinedev/antd/dist/reset.css");
import(/* webpackMode: "eager" */ "/app/src/components/fcm-notification/fcm-notification.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/fcm-token-manager/fcm-token-manager.tsx");
import(/* webpackMode: "eager" */ "/app/src/contexts/color-mode/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/providers/auth-provider/auth-provider.ts");
import(/* webpackMode: "eager" */ "/app/src/providers/data-provider/index.ts");
import(/* webpackMode: "eager" */ "/app/src/providers/devtools/index.tsx")