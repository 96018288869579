"use client";

import { useEffect } from "react";
import useFcmToken from "@utils/hooks/useFcmToken";
const FcmTokenManager = () => {
  const {
    fcmToken,
    notificationPermissionStatus
  } = useFcmToken();
  useEffect(() => {
    if (notificationPermissionStatus === "granted" && fcmToken) {
      console.log("FCM Token:", fcmToken);
      // Additional logic if needed, such as notifying the user or handling the token
    }
  }, [fcmToken, notificationPermissionStatus]);
  return null;
};
export default FcmTokenManager;