"use client";

import { CategoryShow } from "@components/Categories/CategoryShow/CategoryShow";
import { ColorModeContext } from "@contexts/color-mode";
import { EditButton, getDefaultSortOrder, ImageField, List, ShowButton, useTable } from "@refinedev/antd";
import { BaseKey, BaseRecord } from "@refinedev/core";
import formatDateToFrench from "@utility/formatDateToFrench/formatDateToFrench";
import { Button, Form, Input, Space, Table } from "antd";
import React, { ChangeEvent, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
export default function CategoryList() {
  const [selectedRecordId, setSelectedRecordId] = useState<BaseKey | undefined>(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [visibleShowDrawer, setVisibleShowDrawer] = useState<boolean>(false);
  const {
    mode
  } = useContext(ColorModeContext);
  const {
    tableProps,
    searchFormProps,
    setFilters,
    tableQueryResult,
    sorters
  } = useTable({
    syncWithLocation: true,
    resource: "category",
    filters: {
      initial: [{
        field: "name->>name_en",
        operator: "ne",
        value: uuidv4()
      }]
    },
    sorters: {
      initial: [{
        field: "created_at",
        order: "desc"
      }]
    },
    onSearch: (values: any) => [{
      operator: "or",
      value: [{
        field: "name->>name_fr",
        operator: "contains",
        value: "%" + values.name + "%"
      }, {
        field: "name->>name_ar",
        operator: "contains",
        value: "%" + values.name + "%"
      }, {
        field: "name->>name_en",
        operator: "contains",
        value: "%" + values.name + "%"
      }]
    }]
  });
  const resetFilters = () => {
    setFilters([{
      field: "name->>name_en",
      operator: "ne",
      value: uuidv4()
    }], "replace");
    searchFormProps.form?.resetFields();
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    searchFormProps.form?.submit();
  };
  return <div data-sentry-component="CategoryList" data-sentry-source-file="page.tsx">
      <List createButtonProps={{
      style: {
        backgroundColor: "#C59204"
      },
      children: <span>Create Category</span>
    }} data-sentry-element="List" data-sentry-source-file="page.tsx">
        <div className={`flex flex-col w-full min-h-[11rem] py-[1rem] px-[1.5rem] space-y-[0.5rem] mb-[2rem] ${mode === "dark" ? "bg-black" : "bg-white"} `}>
          <h2 className={`text-lg font-semibold mb-2 ${mode === "dark" ? "text-white" : "text-black"}`}>
            Filters
          </h2>
          <Form {...searchFormProps} layout="inline" className={"flex flex-wrap justify-between space-y-[0.5rem]"} data-sentry-element="Form" data-sentry-source-file="page.tsx">
            <Form.Item name="name" className={"min-w-[15rem] "} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              <Input placeholder="Search by name" value={searchValue} onChange={handleSearchInputChange} // Handle search input change
            data-sentry-element="Input" data-sentry-source-file="page.tsx" />
            </Form.Item>
            <Form.Item style={{
            bottom: 0,
            right: 0
          }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              <Button danger onClick={resetFilters} data-sentry-element="Button" data-sentry-source-file="page.tsx">
                Clear Filters
              </Button>
            </Form.Item>
          </Form>
        </div>
        <p className={"flex justify-end text-end text-[1rem] "}>
          {tableQueryResult?.data?.total} résultat(s)
        </p>
        <Table {...tableProps} rowKey="id" data-sentry-element="Table" data-sentry-source-file="page.tsx">
          <Table.Column dataIndex={["image_url"]} title="Image" render={(value: any) => <ImageField style={{
          maxWidth: "50px"
        }} value={value ?? "/assets/images/default-image/df-img.png"} />} data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
          <Table.Column dataIndex="name->>name_en" title="name EN" sorter={{
          multiple: 1
        }} defaultSortOrder={getDefaultSortOrder("name->>name_en", sorters)} render={(value, record: any) => {
          console.log(value);
          return record?.name?.name_en;
        }} data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
          <Table.Column dataIndex="name->>name_fr" title="name FR" sorter={{
          multiple: 1
        }} defaultSortOrder={getDefaultSortOrder("name->>name_fr", sorters)} render={(value, record: any) => {
          console.log(value);
          return record?.name?.name_fr;
        }} data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
          <Table.Column dataIndex="name->>name_ar" title="name AR" sorter={{
          multiple: 1
        }} defaultSortOrder={getDefaultSortOrder("name->>name_ar", sorters)} render={(value, record: any) => {
          console.log(value);
          return record?.name?.name_ar;
        }} data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
          <Table.Column dataIndex="created_at" title="creation date" sorter={{
          multiple: 1
        }} defaultSortOrder={getDefaultSortOrder("created_at", sorters)} render={(value, record: any) => {
          console.log(value);
          return formatDateToFrench(record?.created_at);
        }} data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
          <Table.Column title={"Actions"} dataIndex="actions" render={(_, record: BaseRecord) => <Space>
                <EditButton type={"primary"} hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} onClick={() => {
            setVisibleShowDrawer(true);
            setSelectedRecordId(record.id);
          }} />
                {visibleShowDrawer && selectedRecordId && <CategoryShow ressource={"category"} close={() => setVisibleShowDrawer(false)} recordItemId={selectedRecordId} />}
              </Space>} data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
        </Table>
      </List>
    </div>;
}