"use client";

import { useEffect } from "react";
import { getMessaging, onMessage } from "firebase/messaging";
import app from "@utils/firebase/firebase";
const FCMNotification = () => {
  useEffect(() => {
    console.log("Fcm notification component has been called");
    const messaging = getMessaging(app);
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        navigator.serviceWorker.addEventListener("message", event => {
          if (event?.data?.soundUrl) {
            const audio = new Audio(event.data.soundUrl);
            audio.play().catch(error => console.error("Error playing sound:", error));
          }
        });
      });
      onMessage(messaging, payload => {
        console.log("Message received. ", payload);
        if (payload.notification) {
          const notificationOptions = {
            body: payload.notification.body || "",
            icon: "./assets/logos/itkann-old-logo/itkann.jpg",
            data: {
              sound: "/assets/audio/mixkit-bell-notification-933.wav"
            }
          };
          if (Notification.permission === "granted") {
            if (notificationOptions?.data?.sound) {
              const audio = new Audio(notificationOptions.data.sound);
              audio.play().catch(error => console.error("Error playing sound:", error));
            }
          }
        }
      });
    }
  }, []);
  return null;
};
export default FCMNotification;