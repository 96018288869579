"use client";

import { CategoryInfoList } from "@components/Categories/components/CategoryInfoList/CategoryInfoList";
import SubCategories from "@components/Categories/components/SubCategories/SubCategories";
import { BaseKey, useOne } from "@refinedev/core";
import { Drawer, Flex, Grid } from "antd";
export const CategoryShow = ({
  recordItemId,
  close,
  ressource
}: {
  recordItemId: BaseKey | undefined;
  close: () => void;
  ressource: string;
}) => {
  const breakpoint = Grid.useBreakpoint();
  const {
    data,
    isLoading
  } = useOne({
    resource: ressource,
    id: recordItemId
  });
  const record = data?.data;
  return <Drawer open={!isLoading} onClose={close} width={breakpoint.sm ? "736px" : "100%"} data-sentry-element="Drawer" data-sentry-component="CategoryShow" data-sentry-source-file="CategoryShow.tsx">
      <Flex vertical gap={32} style={{
      padding: "32px"
    }} data-sentry-element="Flex" data-sentry-source-file="CategoryShow.tsx">
        <CategoryInfoList record={record} isLoading={isLoading} id={record?.parent_category_id} data-sentry-element="CategoryInfoList" data-sentry-source-file="CategoryShow.tsx" />
        {!record?.parent_category_id && <SubCategories record={record} />}
      </Flex>
    </Drawer>;
};