import moment from "moment";
import "moment/locale/fr"; // Import French locale

function formatDateToFrench(date: Date | string): string {
  // Check if the input is a valid date
  if (!date || isNaN(new Date(date).getTime())) {
    return "Invalid Date";
  }

  // Set locale to French
  moment.locale("fr");

  // Format the date using moment
  const formattedDate = moment(date).format("LLLL");

  return formattedDate;
}

export default formatDateToFrench;
