"use client";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";
export default function GlobalError({
  error
}: Readonly<{
  error: Error & {
    digest?: string;
  };
}>) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html lang="en" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <Error statusCode={error.props.statusCode} data-sentry-element="Error" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}